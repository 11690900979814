<template>
  <page-setup>
    <v-container fluid class="ma-0 pa-0 fill-height px-10 mt-5">
      <v-row dense>
         <v-col>
            <div class="d-flex align-center my-4">
              <div class="text-h5 text-color-main font-weight-black">
                {{ title }}
              </div>
              <div class="field-width">
                <location-select-box
                  v-model="selectedLocation"
                  solo
                  dense
                  hide-details
                  flat
                  item-text="name"
                  item-value="name"
                  class="ml-8 mr-4 rounded-lg"
                  placeholder="Location"
                  :all-option="true"
                  @change="setLocation()"
                >
                </location-select-box>
              </div>
              <div class="field-width">
                <payment-type-select-box
                  v-model="params.payment_type"
                  solo
                  dense
                  hide-details
                  flat
                  all-option
                  class="ml-8 mr-4 rounded-lg"
                  placeholder="Payment Type"
                  @change="setPaymentType()"
                ></payment-type-select-box>
              </div>
              <div>
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="params.date_from"
                      label="Start Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="ml-5 mr-2 rounded-lg"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="startDateMenu = false"
                    v-model="params.date_from"
                    @change="getData()"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div>
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="params.date_to"
                      label="End Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 rounded-lg"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="endDateMenu = false"
                    v-model="params.date_to"
                    @change="getData()"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
         </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            class="rounded-lg mb-5"
            :loading="loading"
            :server-items-length="totalItems"
            :options.sync="options"
            @update:options="paginate"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              <span class="text-color-main caption text-no-wrap">
                {{ item.created_at | MIXINS_FULL_DATE }}
              </span>
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | amountFormat }}
            </template>
          </v-data-table>
        </v-col>
        </v-row>
    </v-container>
  </page-setup>
</template>

<script>
import moment from 'moment';
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const donationRepository = RepositoryFactory.get("donation");

export default {
  filters: {
    amountFormat: function(value) {
      if (value) {
        return value.toFixed(2);
      }

      return "0.00";
    }
  },
  components: {
    PaymentTypeSelectBox: () => import("@/components/miscellaneous/selects/PaymentTypeSelectBox")
  },
  data: () => ({
    title: "Donation Logs",
    headers: [
      { text: "Email", value: "email", sortable: false },
      { text: "Full Name", value: "name", sortable: false },
      { text: "Location", value: "location", sortable: false },
      { text: "Payment Type", value: "type", sortable: false },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Created At", value: "created_at", sortable: false },
    ],
    items: [],
    options: {
      page: 1,
      itemsPerPage: 15,
    },
    loading: false,
    params: {
      location: null,
      // search: null,
      date_from: null,
      date_to: null,
      payment_type: null
    },
    selectedLocation: null,
    startDateMenu: false,
    endDateMenu: false,
    totalItems: 0,
    // paymentTypes: [
    //   { name: "Push Pay", value: "pushpay" },
    //   { name: "Venmo", value: "venmo" },
    //   { name: "Paypal", value: "paypal" }
    // ]
  }),
  methods: {
    async paginate() {
      this.params.page = this.options.page;
      this.params.per_page = this.options.itemsPerPage;

      await this.getData();
    },
     getData() {
      let params = { ...this.params };

      this.loading = true;

      donationRepository
        .getDonationLogs({ params })
        .then(response => {
          let {
            data: {
              responseData
            }
          } = response;

          let items = responseData.data.map(item => {
            return {
              id: item.id,
              name: `${item.user.first_name} ${item.user.last_name}`,
              type: item.type,
              email: item.user.email,
              location: item.location,
              amount: item.amount,
              created_at: item.created_at
            }
          });

          this.items = items;

          this.totalItems = responseData.total;
        })
        .catch(() => {
          this.donations = []
        })
        .finally(() => {
          this.loading = false;
        })
    },
    setLocation() {
      if (this.selectedLocation !== "All") {
        this.params.location = this.selectedLocation.replace("Inspire", "Inspire Church");
      } else {
        delete this.params.location;
      }

      this.getData();
    },
    async setPaymentType() {
      if (this.params.payment_type === "all") {
        this.params.payment_type = null;
      }

      await this.getData();
    }
  },
  created() {
    this.params.date_from = moment().startOf('month').format("YYYY-MM-DD");
    this.params.date_to = moment().endOf('month').format("YYYY-MM-DD");
  }
}
</script>