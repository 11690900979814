var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-setup',[_c('v-container',{staticClass:"ma-0 pa-0 fill-height px-10 mt-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"d-flex align-center my-4"},[_c('div',{staticClass:"text-h5 text-color-main font-weight-black"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"field-width"},[_c('location-select-box',{staticClass:"ml-8 mr-4 rounded-lg",attrs:{"solo":"","dense":"","hide-details":"","flat":"","item-text":"name","item-value":"name","placeholder":"Location","all-option":true},on:{"change":function($event){return _vm.setLocation()}},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}})],1),_c('div',{staticClass:"field-width"},[_c('payment-type-select-box',{staticClass:"ml-8 mr-4 rounded-lg",attrs:{"solo":"","dense":"","hide-details":"","flat":"","all-option":"","placeholder":"Payment Type"},on:{"change":function($event){return _vm.setPaymentType()}},model:{value:(_vm.params.payment_type),callback:function ($$v) {_vm.$set(_vm.params, "payment_type", $$v)},expression:"params.payment_type"}})],1),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5 mr-2 rounded-lg",attrs:{"label":"Start Date","readonly":"","hide-details":""},model:{value:(_vm.params.date_from),callback:function ($$v) {_vm.$set(_vm.params, "date_from", $$v)},expression:"params.date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startDateMenu = false},"change":function($event){return _vm.getData()}},model:{value:(_vm.params.date_from),callback:function ($$v) {_vm.$set(_vm.params, "date_from", $$v)},expression:"params.date_from"}})],1)],1),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2 rounded-lg",attrs:{"label":"End Date","readonly":"","hide-details":""},model:{value:(_vm.params.date_to),callback:function ($$v) {_vm.$set(_vm.params, "date_to", $$v)},expression:"params.date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endDateMenu = false},"change":function($event){return _vm.getData()}},model:{value:(_vm.params.date_to),callback:function ($$v) {_vm.$set(_vm.params, "date_to", $$v)},expression:"params.date_to"}})],1)],1)])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{staticClass:"rounded-lg mb-5",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","loading":_vm.loading,"server-items-length":_vm.totalItems,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.paginate]},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("MIXINS_FULL_DATE")(item.created_at))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("amountFormat")(item.amount))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }